import React, { useEffect, useState } from "react";
import "./style.css";
import TableDictionary from "./Components/TableDictionary";
import Select from "./Components/Select";
import Learn1 from "./Components/Learn1";
import TestDictionary from "./Components/TestDictionary";
import WriteDictionary from "./Components/WriteDictionary";
import { DataDictionary } from "./Data";
import img from "./Components/Images/img.jpg";

function App() {
    const [dictionary, setDictionary] = useState([]);
    const [selectCount, setSelectCount] = useState(0);
    const [isSelectTable, setIsSelectTable] = useState(false);
    const [isSelectTest, setIsSelectTest] = useState(false);
    const [isSelectWrite, setIsSelectWrite] = useState(false);

    useEffect(() => {
        setDictionary(DataDictionary);
    }, []);

    return (
        <div className="container">
            <div className="left">
                <div className="left-card">
                    <img src={img} alt="Umidjon Yusufov" />
                    <h2 className="left-card-title">Umidjon Yusufov</h2>
                    <h3 className="left-card-job">Frontend Developer</h3>
                    <h4>
                        Dasturchimizni qilgan mehnatlarini qo'llab quvvatlash
                        maqsadida uning ijtimoiy tarmoqlariga obuna bo'ling!
                    </h4>
                    <div className="social-btns">
                        <a
                            target={`_blank`}
                            href="https://t.me/umidjon_freelancer"
                        >
                            Telegram
                        </a>
                        <a
                            target={`_blank`}
                            href="https://www.instagram.com/umidjon_web_developer/"
                        >
                            Instagram
                        </a>
                        <a
                            target={`_blank`}
                            href="https://www.youtube.com/channel/UCdNBzMOcY3WYQMDoXlNgjaA"
                        >
                            Youtube
                        </a>
                    </div>
                </div>
                <div className="info-ilova">
                    <h1>Dasturimiz kelajagi:</h1>
                    <h3>
                        Hozirda Ingliz tili bo'yicha {dictionary.length} ta so'z
                        mavjud.
                    </h3>
                    <h3>Hozirda Rus tili bo'yicha 0 ta so'z mavjud.</h3>
                    <h3>
                        Tez orada Test orqali yodlash usulini (Uzbek-English)
                        qo'shiladi
                    </h3>
                    <h3>
                        Tez orada Yozish orqali yodlash usulini (Uzbek-English)
                        qo'shiladi
                    </h3>
                    <h3>Rus tili bo'limi ham qo'shiladi.</h3>
                </div>
            </div>
            <div className="right">
                <div className="dictionary-help">
                    <Select setSelectCount={setSelectCount} />
                    <Learn1
                        setIsSelectTable={setIsSelectTable}
                        setIsSelectTest={setIsSelectTest}
                        setIsSelectWrite={setIsSelectWrite}
                    />
                </div>
                <div className="dictionary">
                    <div className="dictionary-header">
                        <h1 className="title">Elementar so'zlar</h1>
                    </div>
                    <div className="dictionary-body">
                        {isSelectTable ? (
                            <TableDictionary
                                dictionary={dictionary}
                                selectCount={selectCount}
                            />
                        ) : (
                            ""
                        )}
                        {isSelectTest ? (
                            <TestDictionary
                                dictionary={dictionary}
                                selectCount={selectCount}
                            />
                        ) : (
                            ""
                        )}
                        {isSelectWrite ? (
                            <WriteDictionary
                                dictionary={dictionary}
                                selectCount={selectCount}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
