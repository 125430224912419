export const DataDictionary = [
    {
        idAll: 1,
        idMain: 1,
        id: 1,
        english: "I",
        inenglish: [
            "I",
            "You",
            "We",
            "He",
        ],
        uzbek: "Men",
        inuzbek: [
            "Men",
            "Sen",
            "Biz",
            "U(o'g'il bola)",
        ],
        transciption: "(ay)"

    },
    {
        idAll: 2,
        idMain: 1,
        id: 2,
        english: "You",
        inenglish: [
            "You",
            "I",
            "She",
            "He",
        ],
        uzbek: "Sen",
        inuzbek: [
            "Sen",
            "Men",
            "Biz",
            "U(qiz bola)",
        ],
        transciption: "(yu)"
    },
    {
        idAll: 3,
        idMain: 1,
        id: 3,
        english: "We",
        inenglish: [
            "We",
            "You",
            "I",
            "He",
        ],
        uzbek: "Biz",
        inuzbek: [
            "Biz",
            "Sen",
            "U(qiz bola)",
            "U(o'g'il bola)",
        ],
        transciption: "(ui)"
    },
    {
        idAll: 4,
        idMain: 1,
        id: 4,
        english: "He",
        inenglish: [
            "He",
            "You",
            "We",
            "She",
        ],
        uzbek: "U(o'g'il bola)",
        inuzbek: [
            "U(o'g'il bola)",
            "Sen",
            "Biz",
            "U(qiz bola)",
        ],
        transciption: "(hi)"
    },
    {
        idAll: 5,
        idMain: 1,
        id: 5,
        english: "She",
        inenglish: [
            "She",
            "You",
            "We",
            "He",
        ],
        uzbek: "U(qiz bola)",
        inuzbek: [
            "U(qiz bola)",
            "Sen",
            "Biz",
            "U(o'g'il bola)",
        ],
        transciption: "(shi)"
    },
    {
        idAll: 6,
        idMain: 1,
        id: 6,
        english: "It",
        inenglish: [
            "It",
            "She",
            "We",
            "You",
        ],
        uzbek: "U(narsa)",
        inuzbek: [
            "U(narsa)",
            "Sen",
            "U(qiz bola)",
            "U(o'g'il bola)",
        ],
        transciption: "(it)"
    },
    {
        idAll: 7,
        idMain: 1,
        id: 7,
        english: "They",
        inenglish: [
            "They",
            "It",
            "She",
            "He",
        ],
        uzbek: "Ular",
        inuzbek: [
            "Ular",
            "Sen",
            "Biz",
            "U(o'g'il bola)",
        ],
        transciption: "(dey)"
    },
    {
        idAll: 8,
        idMain: 1,
        id: 8,
        english: "What",
        inenglish: [
            "What",
            "Who",
            "Where",
            "How",
        ],
        uzbek: "nima",
        inuzbek: [
            "nima",
            "kim",
            "qayerga",
            "nega",
        ],
        transciption: "(uot)"
    },
    {
        idAll: 9,
        idMain: 1,
        id: 9,
        english: "Who",
        inenglish: [
            "Who",
            "Why",
            "Where",
            "How",
        ],
        uzbek: "kim",
        inuzbek: [
            "kim",
            "qayerga",
            "qanday",
            "nega",
        ],
        transciption: "(hu)"
    },
    {
        idAll: 10,
        idMain: 1,
        id: 10,
        english: "Where",
        inenglish: [
            "Where",
            "Why",
            "Who",
            "How",
        ],
        uzbek: "qayerga",
        inuzbek: [
            "qayerga",
            "qanday",
            "nega",
            "qachon",
        ],
        transciption: "(ueya)"
    },
    {
        idAll: 11,
        idMain: 1,
        id: 11,
        english: "How",
        inenglish: [
            "How",
            "When",
            "Who",
            "What",
        ],
        uzbek: "qanday",
        inuzbek: [
            "qanday",
            "nega",
            "qachon",
            "qayerga",
        ],
        transciption: "(hau)"
    },
    {
        idAll: 12,
        idMain: 1,
        id: 12,
        english: "Why",
        inenglish: [
            "Why",
            "When",
            "Where",
            "How",
        ],
        uzbek: "nega",
        inuzbek: [
            "nega",
            "qachon",
            "qayerga",
            "kim",
        ],
        transciption: "(uay)"
    },
    {
        idAll: 13,
        idMain: 1,
        id: 13,
        english: "When",
        inenglish: [
            "When",
            "Why",
            "How",
            "Where",
        ],
        uzbek: "qachon",
        inuzbek: [
            "qachon",
            "qayerga",
            "kim",
            "nega",
        ],
        transciption: "(uen)"
    },
    {
        idAll: 14,
        idMain: 1,
        id: 14,
        english: "My",
        inenglish: [
            "My",
            "Your",
            "His",
            "Her",
        ],
        uzbek: "mening",
        inuzbek: [
            "mening",
            "sening",
            "uning(o'g'il bola)",
            "uning(qiz bola)",
        ],
        transciption: "(may)"
    },
    {
        idAll: 15,
        idMain: 1,
        id: 15,
        english: "Your",
        inenglish: [
            "Your",
            "His",
            "Her",
            "Its",
        ],
        uzbek: "sening",
        inuzbek: [
            "sening",
            "mening",
            "bizning",
            "uning(narsa)",
        ],
        transciption: "(yor)"
    },
    {
        idAll: 16,
        idMain: 1,
        id: 16,
        english: "His",
        inenglish: [
            "His",
            "Her",
            "Its",
            "Our",
        ],
        uzbek: "uning(o'g'il bola)",
        inuzbek: [
            "uning(o'g'il bola)",
            "sening, sizning",
            "bizning",
            "uning(qiz bola)",
        ],
        transciption: "(his)"
    },
    {
        idAll: 17,
        idMain: 1,
        id: 17,
        english: "Her",
        inenglish: [
            "Her",
            "Its",
            "Our",
            "His",
        ],
        uzbek: "uning(qiz bola)",
        inuzbek: [
            "uning(qiz bola)",
            "sening",
            "bizning",
            "uning(o'g'il bola)",
        ],
        transciption: "(hyor)"
    },
    {
        idAll: 18,
        idMain: 1,
        id: 18,
        english: "Its",
        inenglish: [
            "Its",
            "Her",
            "His",
            "Their",
        ],
        uzbek: "uning(narsa)",
        inuzbek: [
            "uning(narsa)",
            "sening",
            "uning(o'g'il bola)",
            "uning(qiz bola)",
        ],
        transciption: "(its)"
    },
    {
        idAll: 19,
        idMain: 1,
        id: 19,
        english: "Our",
        inenglish: [
            "Our",
            "Their",
            "Its",
            "Her",
        ],
        uzbek: "bizning",
        inuzbek: [
            "bizning",
            "sening",
            "ularning",
            "uning(o'g'il bola)",
        ],
        transciption: "(aur)"
    },
    {
        idAll: 20,
        idMain: 1,
        id: 20,
        english: "Their",
        inenglish: [
            "I",
            "You",
            "We",
            "He",
        ],
        uzbek: "ularning",
        inuzbek: [
            "ularning",
            "sening",
            "bizning",
            "uning(narsa)",
        ],
        transciption: "(deir)"
    },
    {
        idAll: 21,
        idMain: 2,
        id: 1,
        english: "Cat",
        inenglish: [
            "Cat",
            "Dog",
            "Bird",
            "Squirrel",
        ],
        uzbek: "mushuk",
        inuzbek: [
            "mushuk",
            "kuchuk",
            "qush",
            "olmaxon",
        ],
        transciption: "(ket)"
    },
    {
        idAll: 22,
        idMain: 2,
        id: 2,
        english: "Dog",
        inenglish: [
            "Dog",
            "Cat",
            "Bird",
            "Squirrel",
        ],
        uzbek: "kuchuk",
        inuzbek: [
            "kuchuk",
            "mushuk",
            "qush",
            "olmaxon",
        ],
        transciption: "(dog)"
    },
    {
        idAll: 23,
        idMain: 2,
        id: 3,
        english: "Bird",
        inenglish: [
            "Bird",
            "Dog",
            "Cat",
            "Squirrel",
        ],
        uzbek: "qush",
        inuzbek: [
            "qush",
            "kuchuk",
            "mushuk",
            "olmaxon",
        ],
        transciption: "(byord)"
    },
    {
        idAll: 24,
        idMain: 2,
        id: 4,
        english: "Squirrel",
        inenglish: [
            "Squirrel",
            "Dog",
            "Bird",
            "Cat",
        ],
        uzbek: "olmaxon",
        inuzbek: [
            "olmaxon",
            "kuchuk",
            "qush",
            "mushuk",
        ],
        transciption: "(skuirel)"
    },
    {
        idAll: 25,
        idMain: 2,
        id: 5,
        english: "Wolf",
        inenglish: [
            "Wolf",
            "Goose",
            "Giraffe",
            "Rabbit",
        ],
        uzbek: "bo'ri",
        inuzbek: [
            "bo'ri",
            "g'oz",
            "jirafa",
            "quyon",
        ],
        transciption: "(uolf)"
    },
    {
        idAll: 26,
        idMain: 2,
        id: 6,
        english: "Goose",
        inenglish: [
            "Goose",
            "Wolf",
            "Giraffe",
            "Rabbit",
        ],
        uzbek: "g'oz",
        inuzbek: [
            "g'oz",
            "bo'ri",
            "jirafa",
            "quyon",
        ],
        transciption: "(gus)"
    },
    {
        idAll: 27,
        idMain: 2,
        id: 7,
        english: "Giraffe",
        inenglish: [
            "Giraffe",
            "Wolf",
            "Goose",
            "Rabbit",
        ],
        uzbek: "jirafa",
        inuzbek: [
            "jirafa",
            "bo'ri",
            "g'oz",
            "quyon",
        ],
        transciption: "(jiraf)"
    },
    {
        idAll: 28,
        idMain: 2,
        id: 8,
        english: "Rabbit",
        inenglish: [
            "Rabbit",
            "Wolf",
            "Giraffe",
            "Goose",
        ],
        uzbek: "quyon",
        inuzbek: [
            "quyon",
            "bo'ri",
            "jirafa",
            "g'oz",
        ],
        transciption: "(rebbit)"
    },
    {
        idAll: 29,
        idMain: 2,
        id: 9,
        english: "Cow",
        inenglish: [
            "Cow",
            "Rat",
            "Fox",
            "Horse",
        ],
        uzbek: "sigir",
        inuzbek: [
            "sigir",
            "kalamush",
            "tulki",
            "ot",
        ],
        transciption: "(kau)"
    },
    {
        idAll: 30,
        idMain: 2,
        id: 10,
        english: "Rat",
        inenglish: [
            "Rat",
            "Cow",
            "Fox",
            "Horse",
        ],
        uzbek: "kalamush",
        inuzbek: [
            "kalamush",
            "sigir",
            "tulki",
            "ot",
        ],
        transciption: "(ret)"
    },
    {
        idAll: 31,
        idMain: 2,
        id: 11,
        english: "Fox",
        inenglish: [
            "Fox",
            "Cow",
            "Rat",
            "Horse",
        ],
        uzbek: "tulki",
        inuzbek: [
            "tulki",
            "sigir",
            "kalamush",
            "ot",
        ],
        transciption: "(foks)"
    },
    {
        idAll: 32,
        idMain: 2,
        id: 12,
        english: "Horse",
        inenglish: [
            "Horse",
            "Cow",
            "Rat",
            "Fox",
        ],
        uzbek: "ot",
        inuzbek: [
            "ot",
            "sigir",
            "kalamush",
            "tulki",
        ],
        transciption: "(hors)"
    },
    {
        idAll: 33,
        idMain: 2,
        id: 13,
        english: "Frog",
        inenglish: [
            "Frog",
            "Bear",
            "Mouse",
            "Monkey",
        ],
        uzbek: "qurbaqa",
        inuzbek: [
            "qurbaqa",
            "ayiq",
            "sichqon",
            "maymun",
        ],
        transciption: "(frog)"
    },
    {
        idAll: 34,
        idMain: 2,
        id: 14,
        english: "Bear",
        inenglish: [
            "Bear",
            "Frog",
            "Mouse",
            "Monkey",
        ],
        uzbek: "ayiq",
        inuzbek: [
            "ayiq",
            "qurbaqa",
            "sichqon",
            "maymun",
        ],
        transciption: "(biyer)"
    },
    {
        idAll: 35,
        idMain: 2,
        id: 15,
        english: "Mouse",
        inenglish: [
            "Mouse",
            "Frog",
            "Bear",
            "Monkey",
        ],
        uzbek: "sichqon",
        inuzbek: [
            "sichqon",
            "qurbaqa",
            "ayiq",
            "maymun",
        ],
        transciption: "(maus)"
    },
    {
        idAll: 36,
        idMain: 2,
        id: 16,
        english: "Monkey",
        inenglish: [
            "Monkey",
            "Frog",
            "Bear",
            "Mouse",
        ],
        uzbek: "maymun",
        inuzbek: [
            "maymun",
            "qurbaqa",
            "ayiq",
            "sichqon",
        ],
        transciption: "(manki)"
    },
    {
        idAll: 37,
        idMain: 2,
        id: 17,
        english: "Pig",
        inenglish: [
            "Pig",
            "Elephant",
            "Duck",
            "Lion",
        ],
        uzbek: "cho'chqa",
        inuzbek: [
            "cho'chqa",
            "fil",
            "o'rdak",
            "sher",
        ],
        transciption: "(pig)"
    },
    {
        idAll: 38,
        idMain: 2,
        id: 18,
        english: "Elephant",
        inenglish: [
            "Elephant",
            "Pig",
            "Duck",
            "Lion",
        ],
        uzbek: "fil",
        inuzbek: [
            "fil",
            "cho'chqa",
            "o'rdak",
            "sher",
        ],
        transciption: "(elifent)"
    },
    {
        idAll: 39,
        idMain: 2,
        id: 19,
        english: "Duck",
        inenglish: [
            "Duck",
            "Pig",
            "Elephant",
            "Lion",
        ],
        uzbek: "o'rdak",
        inuzbek: [
            "o'rdak",
            "fil",
            "cho'chqa",
            "sher",
        ],
        transciption: "(dak)"
    },
    {
        idAll: 40,
        idMain: 2,
        id: 20,
        english: "Lion",
        inenglish: [
            "Lion",
            "Pig",
            "Elephant",
            "Duck",
        ],
        uzbek: "sher",
        inuzbek: [
            "sher",
            "fil",
            "cho'chqa",
            "o'rdak",
        ],
        transciption: "(layn)"
    },
]