import React from 'react'

function Learn1({setIsSelectTable,setIsSelectTest, setIsSelectWrite}) {
    function select(e){
        if(e.target.value === "1"){
            setIsSelectTable(true);
            setIsSelectTest(false);
            setIsSelectWrite(false);
        }
        if(e.target.value === "2"){
            setIsSelectTable(false);
            setIsSelectTest(false);
            setIsSelectWrite(false);
        }
        if(e.target.value === "3"){
            setIsSelectTable(false);
            setIsSelectTest(true);
            setIsSelectWrite(false);
        }
        if(e.target.value === "4"){
            setIsSelectTable(false);
            setIsSelectTest(false);
            setIsSelectWrite(true);
        }
        if(e.target.value === "5"){
            setIsSelectTable(false);
            setIsSelectTest(false);
            setIsSelectWrite(false);
        }
        if(e.target.value === "0"){
            setIsSelectTable(false);
            setIsSelectTest(false);
            setIsSelectWrite(false);
        }
        
    }
    return (
        <div className="learn-one">
            <h3>O'rganish usulini tanlang</h3>
            <select onChange={select} className="learn-one__select" name="learn-one" id="learn-one">
                <option value="0">...</option>
                <option value="1">Jadval orqali yodlash</option>
                <option value="2">Test orqali yodlash(Uzbek-English)</option>
                <option value="3">Test orqali yodlash(English-Uzbek)</option>
                <option value="4">Yozish orqali yodlash(English-Uzbek)</option>
                <option value="5">Yozish orqali yodlash(Uzbek-English)</option>

            </select>
        </div>
    )
}

export default Learn1
